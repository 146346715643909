import { Box, Heading, Icon, useBreakpoint } from '@hyphen/hyphen-components';
import { CONNECTION_NAME_MAP, INTEGRATION_ICON_MAP, INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { Link, Route, Routes } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import { buildConnectionUrl, ConnectionLink } from '../../../components/integrations/connections/ConnectionLink';
import { useConnection } from '../../../providers/ConnectionProvider';
import ConnectionAccess from './Connections/ConnectionAccess';
import ConnectionOverview from './Connections/ConnectionOverview';
import { ConnectionType } from '../../../types/connections';
import { useMemo } from 'react';
import { Skeleton } from '../../../components/common/Skeleton';

export const IntegrationConnectionDetails = () => {
  const { connection, isLoading, params } = useConnection();
  const { isPhone, isTablet } = useBreakpoint();

  const hasAccessTab =
    connection?.type === ConnectionType.PermissionGroup || connection?.type === ConnectionType.User;
  const hasMembersTab = connection?.type !== ConnectionType.User;

  const connectionTitle = useMemo(
    () =>
      !isLoading && connection ? buildConnectionUrl(connection)?.label : <Skeleton width="200px" height="30px" />,
    [connection, isLoading],
  );

  const pageHeaderProps =
    !isLoading && connection
      ? {
        title: connectionTitle,
        iconUrl: INTEGRATION_ICON_MAP[connection.organizationIntegration.type],
        description: `${INTEGRATION_NAME_MAP[connection.organizationIntegration.type]} ${CONNECTION_NAME_MAP[connection.type]
          }`,
      }
      : {
        title: <Skeleton width="250px" height={isPhone ? '25px' : isTablet ? '35px' : '40px'} />,
        iconUrl: 'loading',
        description: <Skeleton width="150px" height="xl" />,
      };

  return (
    <ScrollableMainLayout>
      {(!isLoading && !connection) ? (
        <IntegrationConnectionNotFound
          organizationId={params.organizationId!}
          integrationId={params.integrationId!}
          connectionId={params.connectionId!}
        />
      ) : (
        <>
          <Helmet>
            <title>{connection?.config?.name || ''}</title>
          </Helmet>
          <PageHeader {...pageHeaderProps} />
          <BreadCrumbPortal entity={connection} />

          <Box
            direction={{ base: 'column', tablet: 'row' }}
            justifyContent="space-between"
            alignItems={{ base: 'stretch', tablet: 'center' }}
            gap="lg"
          >
            <TabNav>
              <TabNavLink to="">Overview</TabNavLink>
              {hasMembersTab && <TabNavLink to="members">Members</TabNavLink>}
              {hasAccessTab && <TabNavLink to="access">Access</TabNavLink>}
            </TabNav>
            {connection && <ConnectionLink connection={connection} />}
          </Box>
          <Routes>
            <Route path="/*" element={<ConnectionOverview />} />
            {hasMembersTab && <Route path="members" element={<ConnectionAccess />} />}
            {hasAccessTab && <Route path="access" element={<ConnectionAccess />} />}
          </Routes>
        </>
      )}
    </ScrollableMainLayout>
  );
};

function IntegrationConnectionNotFound({
  organizationId,
  integrationId,
  connectionId,
}: {
  organizationId: string;
  integrationId: string;
  connectionId: string;
}) {
  return (
    <Box margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }} width={{ base: '100', desktop: '80' }}>
      <Box
        padding={{ base: 'lg', desktop: '0' }}
        childGap={{
          base: 'lg',
        }}
        height="100"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box fontSize={{ base: '2xl', desktop: '3xl' }} color="secondary" aria-hidden="true">
          ¯\_(ツ)_/¯
        </Box>
        <Heading as="h1" size={{ base: 'lg', desktop: 'xl' }}>
          We couldn't find the connection you're looking for.
        </Heading>
        <Box as="p">
          Connection with the ID `{connectionId}` for integration with ID `{integrationId}` not found.
        </Box>
        <Link to={`/${organizationId}/integrations`}>
          <Box alignItems="center" direction="row" gap="xs">
            <Icon name="caret-sm-left" /> Integrations
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default IntegrationConnectionDetails;
