import {
  Drawer,
  DrawerContent,
  Box,
  useBreakpoint,
  Spinner,
  Card,
  useOpenClose,
  toast,
  DrawerHeader,
  DrawerCloseButton,
  DrawerTitle,
  Button,
  Icon,
  Badge,
} from '@hyphen/hyphen-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useCallback, useMemo } from 'react';
import { useDeleteMemberMutation, useGetMemberQuery } from '../../services/member';
import { ApiError } from '../ApiError';
import EditOrgMemberForm from './EditOrgMemberForm';
import DeleteMemberConfirmationModal from './DeleteMemberConfirmationModal';
import MemberConnections from './MemberConnections';
import { useAuth } from '../auth/authProvider';
import { OrganizationRoles } from '../../types/roles';
import { Helmet } from 'react-helmet';
import OrgAdminButton from './OrgAdminButton';

export default function OrgMemberDrawer() {
  const { memberId } = useParams();
  const { organization = {} as Organization } = useOrganization();
  const navigate = useNavigate();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const [deleteMember, { isLoading: isDeleting, error: deleteError }] = useDeleteMemberMutation();
  const { isPhone, isTablet } = useBreakpoint();
  const ability = useOrganizationAbilityContext();

  const { user } = useAuth();

  const isOrgAdmin = useMemo(() => {
    return user?.memberships
      .find((m) => m.organization.id === organization.id)
      ?.organizationRoles.includes(OrganizationRoles.OrganizationAdmin);
  }, [user, organization.id]);

  const canGiveOrgAdminAccess = useMemo(() => {
    return isOrgAdmin && ability.can('manage', organization);
  }, [isOrgAdmin, ability, organization]);

  const {
    data: member,
    isLoading,
    error,
  } = useGetMemberQuery({
    organizationId: organization.id,
    memberId: memberId || '',
    includeOrganizationRoles: true,
  });

  const isMemberOrgAdmin = useMemo(() => {
    return member?.organizationRoles?.includes(OrganizationRoles.OrganizationAdmin);
  }, [member?.organizationRoles]);

  const canDeleteMember = member && isOrgAdmin && ability.can('delete', member);
  const canUpdateMember = member && ability.can('update', member);

  const handleDismiss = useCallback(() => {
    navigate(`/${organization.id}/settings/members`);
  }, [navigate, organization.id]);

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteMember({ memberId: memberId || '', organizationId: organization.id });
      if (!result.error) {
        toast.success(`Member removed`);
        closeDeleteModal();
        handleDismiss();
      } else {
        toast.error(`Remove member failed`);
      }
    } catch (e) {
      toast.error(`Remove member failed`);
    }
  }, [deleteMember, memberId, organization.id, closeDeleteModal, handleDismiss]);

  const drawerTitle = error
    ? 'Member not found'
    : isLoading
    ? 'Loading...'
    : member?.firstName || member?.lastName
    ? `${member.firstName} ${member.lastName}`
    : member?.email;

  return (
    <>
      <Helmet title={drawerTitle}></Helmet>
      <Drawer
        isOpen
        onDismiss={handleDismiss}
        ariaLabel="environment details"
        width={isPhone ? '90%' : isTablet ? '70%' : '60%'}
      >
        <DrawerHeader>
          <DrawerTitle gap="xs">
            <Box direction="row" gap="sm" alignItems="baseline">
              {drawerTitle}
              {isMemberOrgAdmin && <Badge variant="blue" message="Admin" size="sm" />}
            </Box>
            <Box fontSize="sm" color="secondary" fontWeight="normal">
              {member?.email}
            </Box>
          </DrawerTitle>
          <DrawerCloseButton onClose={handleDismiss} />
        </DrawerHeader>
        <DrawerContent gap={{ base: '2xl', tablet: '3xl' }}>
          {isLoading && <Spinner />}
          {error && <ApiError error={error} />}
          {!isLoading && member && (
            <>
              {canUpdateMember && (
                <Card style={{ flexShrink: '0' }}>
                  <Card.Header
                    title="Member Info"
                    description="Contact info that appears to other members of this organization"
                    borderWidth="0 0 sm 0"
                  />
                  <Card.Section>
                    <EditOrgMemberForm member={member} />
                  </Card.Section>
                </Card>
              )}

              <MemberConnections member={member} organization={organization} />

              {(canGiveOrgAdminAccess || canDeleteMember) && (
                <Card borderColor="danger" style={{ flexShrink: '0' }}>
                  {canGiveOrgAdminAccess && (
                    <Card.Header
                      title="Organization Admin"
                      description="Can manage all projects, integrations, secrets, members, and assign other members as administrators"
                      borderWidth="0 0 sm 0"
                      alignItems="center"
                    >
                      {!isMemberOrgAdmin ? (
                        <OrgAdminButton member={member} organization={organization} />
                      ) : (
                        <Box fontSize="sm" width="60px" alignItems="center" style={{ flexShrink: '0' }}>
                          <Icon name="check" size="xl" color="info" />
                          <span className="sr-only">Is Organization Administrator</span>
                        </Box>
                      )}
                    </Card.Header>
                  )}
                  {canDeleteMember && (
                    <Card.Header
                      title="Delete Member"
                      description="Deleting a member removes their access to this organizations resources"
                      alignItems="center"
                    >
                      <Button variant="danger" size="sm" onClick={openDeleteModal}>
                        Delete
                      </Button>
                    </Card.Header>
                  )}
                </Card>
              )}
            </>
          )}
          {canDeleteMember && member && (
            <DeleteMemberConfirmationModal
              memberEmail={member.email}
              organizationName={organization.name}
              isOpen={isDeleteModalOpen}
              onClose={closeDeleteModal}
              onConfirm={handleDelete}
              isLoading={isDeleting}
              error={deleteError}
            />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}
