import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  toast,
  useIsMobile,
  useOpenClose,
  useTheme,
} from '@hyphen/hyphen-components';
import { useCallback, useMemo } from 'react';
import { ConfirmModal } from '../../common/ConfirmModal';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';
import { Connection } from '../../../types/connections';
import { useDeleteConnectionMutation } from '../../../services/connections';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';
import { buildConnectionUrl } from './ConnectionLink';
import { Link, useNavigate } from 'react-router-dom';
import IntegrationIcon from './IntegrationIcon';
import ConnectionStatusBadge from './ConnectionStatusBadge';
import { getEntityUrl } from '../../../utils/getEntityUrl';
import { EntityIcon } from '../../common/EntityIcon';
import { EntityNames } from '@hyphen/nucleus/dist/types';

interface ConnectionItemProps {
  connection: Connection;
  showEntity?: boolean;
}

export const ConnectionItem = ({ connection, showEntity = false }: ConnectionItemProps) => {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const { id: orgId } = useOrganization();
  const isMobile = useIsMobile();
  const { isDarkMode } = useTheme();

  const [deleteConnection, { isLoading: isDeleting, error }] = useDeleteConnectionMutation();
  const ability = useOrganizationAbilityContext();
  const navigate = useNavigate();

  const canManageThisConnection = ability.can('manage', connection);
  const canViewOrganizationIntegrations = ability.can('view', EntityNames.OrganizationIntegration);

  const handleDelete = useCallback(async () => {
    const { error } = await deleteConnection({
      organizationId: orgId,
      integrationId: connection.organizationIntegration.id,
      connectionId: connection.id,
    });
    if (!error) {
      toast.success('Connection removed!');
      closeDeleteModal();
    }
  }, [deleteConnection, orgId, connection.organizationIntegration.id, connection.id, closeDeleteModal]);

  const handleOpenVerifyModal = useCallback(() => {
    navigate(`/${orgId}/integrations/${connection.organizationIntegration.id}/connections/${connection.id}?verify`);
  }, [orgId, connection, navigate]);

  const showDetails = useMemo(() => {
    if (connection.status === 'Error') {
      return connection.errors && connection.errors.length > 0;
    }
    return connection.config && Object.keys(connection.config).length > 0;
  }, [connection]);

  const connectionTitle = useMemo(() => buildConnectionUrl(connection), [connection]);
  const renderEntityLink = () => {
    if (isMobile) return null;

    return (
      <Box
        width="144px"
        minWidth="144px"
        alignItems="flex-start"
        fontSize="xs"
        color="secondary"
        className="truncate"
      >
        <Link
          to={`${getEntityUrl(
            orgId,
            connection.entity.type,
            connection.entity.id,
            connection.project?.alternateId,
          )}/connections`}
          className="display-flex flex-direction-row g-xs align-items-center"
        >
          <EntityIcon type={connection.entity.type} color="tertiary" size="md" />
          {connection.entity.name}
        </Link>
      </Box>
    );
  };

  const renderIntegrationLink = () => (
    <Box
      width={!isMobile ? '144px' : 'xl'}
      minWidth={!isMobile ? '144px' : 'xl'}
      alignItems="flex-start"
      fontSize="xs"
      color="secondary"
    >
      {canViewOrganizationIntegrations ? (
        <Link to={`/${orgId}/integrations/${connection.organizationIntegration.id}`}>
          <IntegrationIcon type={connection.organizationIntegration.type} isMobile={isMobile} />
        </Link>
      ) : (
        <IntegrationIcon type={connection.organizationIntegration.type} isMobile={isMobile} />
      )}
    </Box>
  );

  return (
    <Box className="row-item" borderWidth="sm 0 0 0">
      <Box
        alignItems="center"
        direction="row"
        gap={{ base: 'lg', tablet: '2xl' }}
        padding={{ base: 'xl 2xl', tablet: '2xl' }}
        fontSize="sm"
      >
        <Box
          flex="auto"
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: 'md', tablet: 'lg', desktop: '2xl' }}
          alignItems={{ tablet: 'center' }}
        >
          <Box width="82px" alignItems="flex-start">
            <ConnectionStatusBadge status={connection.status} />
          </Box>
          <Box flex="auto" childGap="xs" alignItems="flex-start">
            <Box fontWeight="bold" direction="row" gap="lg" className="truncate">
              {showDetails ? (
                <Link
                  to={`/${orgId}/integrations/${connection.organizationIntegration.id}/connections/${connection.id}/`}
                >
                  {connectionTitle?.label}
                </Link>
              ) : (
                connectionTitle?.label
              )}
            </Box>
            <Box fontSize="xs" color="secondary">
              {CONNECTION_NAME_MAP[connection.type]}
            </Box>
          </Box>
        </Box>
        {showEntity ? renderEntityLink() : renderIntegrationLink()}
        {connectionTitle?.href && (
          <div>
            <Button variant="tertiary" iconSuffix="launch-app" size="sm" asChild>
              <Link
                to={connectionTitle.href}
                target="_blank"
                aria-label={`Open ${connectionTitle.label} in new tab`}
              />
            </Button>
          </div>
        )}
        {canManageThisConnection && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" side="bottom">
              <DropdownMenuGroup>
                {connection.status === 'Verifying' && (
                  <DropdownMenuItem onSelect={handleOpenVerifyModal}>
                    <Icon name="check" /> <span>Verify</span>
                  </DropdownMenuItem>
                )}
                {showDetails && (
                  <Link
                    to={`/${orgId}/integrations/${connection.organizationIntegration.id}/connections/${connection.id}/`}
                  >
                    <DropdownMenuItem>
                      <Icon name="c-info" color="tertiary" /> Details
                    </DropdownMenuItem>
                  </Link>
                )}
                {showEntity && isMobile && (
                  <DropdownMenuItem asChild>
                    <Link
                      to={`${getEntityUrl(
                        orgId,
                        connection.entity.type,
                        connection.entity.id,
                        connection.project?.alternateId,
                      )}/connections`}
                    >
                      <EntityIcon type={connection.entity.type} color="tertiary" size="md" />
                      {connection.entity.name}
                    </Link>
                  </DropdownMenuItem>
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                  <Icon name="c-remove" /> <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Box>
      {connection.status === 'Verifying' && connection.verification?.emails.length && (
        <Box fontSize="xs" color={isDarkMode ? 'disabled' : 'secondary'} padding="xl 2xl" background="warning" gap="md">
          Verification email was sent to {connection.verification?.emails.join(', ')}
          <div>
            <Box
              display="inline-flex"
              alignItems="center"
              gap="xs"
              direction="row"
              as="button"
              background="transparent"
              padding="0"
              borderWidth="0"
              color={isDarkMode ? 'black' : 'base'}
              cursor="pointer"
              size="sm"
              variant="secondary"
              onClick={handleOpenVerifyModal}
              className="tdl-underline"
            >
              Verify
              <Icon name="arrow-right" />
            </Box>
          </div>
        </Box>
      )}
      <ConfirmModal
        message={`Are you sure you want to delete this connection?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete Connection`}
        error={error}
      />
    </Box>
  );
};
