import { AccessEntry, ListResponse, QueryParams } from './types';
import { api } from './api';
import { App } from './apps';
import { Environment } from './environments';
import { Segment } from '../types/segments';

export interface Project {
  id: string;
  alternateId: string;
  name: string;
  isMonorepo: boolean;
  organization: {
    id: string;
    name: string;
  };
  apps?: App[];
  environments?: Environment[];
  segments?: Segment[];
}

export interface ProjectParams {
  projectId: string;
  organizationId: string;
}

interface CreateProjectRequestBody {
  name: string;
  alternateId: string;
  organizationId: string;
}

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ListResponse<Project>, QueryParams>({
      query: ({ pageSize, pageNum, organizationId }) => {
        const params = new URLSearchParams();
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/projects?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Projects' as const, id: id })),
              { type: 'Projects', id: 'LIST' },
            ]
          : [],
    }),
    getProject: builder.query<Project, ProjectParams>({
      query: ({ projectId, organizationId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Projects', id: result.alternateId }] : []),
    }),
    createProject: builder.mutation<Project, CreateProjectRequestBody>({
      query: ({ name, alternateId, organizationId }) => ({
        url: `/api/organizations/${organizationId}/projects/`,
        method: 'POST',
        body: { name, alternateId },
      }),
      invalidatesTags: (_result, _error, { organizationId }) => [
        { type: 'Projects', id: 'LIST' },
        { type: 'OrganizationExecutionContext', id: organizationId },
      ],
    }),
    deleteProject: builder.mutation<void, { projectId: string; organizationId: string }>({
      query: ({ projectId, organizationId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: 'Projects', id: projectId },
        { type: 'Projects', id: 'LIST' },
      ],
    }),

    getAccess: builder.query<
      ListResponse<AccessEntry>,
      { projectId: string; organizationId: string; pageNum: number; pageSize: number }
    >({
      query: ({ projectId, organizationId, pageNum, pageSize }) => {
        const params = new URLSearchParams();
        params.append('entityIds', projectId);
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (pageSize) params.append('pageSize', pageSize.toString());
        return {
          url: `api/organizations/${organizationId}/access?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { projectId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'ProjectAccess' as const, id: id })),
              { type: 'ProjectAccess', id: `${projectId}-LIST` },
            ]
          : [],
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetAccessQuery,
} = projectsApi;
