import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';

import { Member } from '../../types/teams';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';

interface MemberConnectionsProps {
  member: Member;
  organization: Organization;
}

export default function MemberConnections({ member, organization }: MemberConnectionsProps) {
  const ability = useOrganizationAbilityContext();

  const canUpdateMember = ability.can('update', member);

  return (
    <ConnectionsList
      organizationId={organization.id}
      filter={{
        entity: {
          type: 'Member',
          id: member.id,
          name: member.nickname!,
        },
        entityType: 'Member',
      }}
      showManualFilter={false}
      showAddButton={canUpdateMember}
    />
  );
}
