import { Box } from '@hyphen/hyphen-components';
import { useGetMemberQuery } from '../../services/member';
import { ApiError } from '../ApiError';
import DateTimeDisplay from '../DateTime';
import { Skeleton } from './Skeleton';

function CreatedBy({
  organizationId,
  memberId,
  createdAt,
}: {
  organizationId: string;
  memberId: string;
  createdAt: string;
}) {
  const { data: member, isLoading, error } = useGetMemberQuery({ organizationId, memberId });

  if (isLoading)
    return (
      <Box gap="sm">
        <Skeleton height="16px" width="120px" />
      </Box>
    );
  if (error) return <ApiError error={error} />;
  if (!isLoading && !member) return null;

  return (
    <Box gap={{ base: 'xs', tablet: 'sm' }} textAlign={{ base: 'right', tablet: 'left' }}>
      <div>{member.nickname || member.email}</div>
      <Box fontSize="xs" color="secondary">
        <DateTimeDisplay value={createdAt} />
      </Box>
    </Box>
  );
}

export default CreatedBy;
