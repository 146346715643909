import { useCallback, useMemo } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useToggle } from '../../providers/ToggleProvider';
import { useGetToggleUsageQuery } from '../../services/toggle';
import { Box, Pagination, Spinner, Table } from '@hyphen/hyphen-components';
import { timeAgo } from '../../utils/dateUtils';
import { ColorDot } from '../common/ColorDot';
import ToggleUsageDrawer from './ToggleUsageDrawer';
import useQueryStringState from '../../hooks/useQueryStringState';
import EmptyList from '../common/EmptyList';

interface ToggleUsageListProps {
  showPagination?: boolean;
  pageSize?: number;
}

export const ToggleUsageList = ({ showPagination = true, pageSize = 25 }: ToggleUsageListProps) => {
  const { id: orgId } = useOrganization();
  const { toggle, isLoading: isToggleLoading } = useToggle();
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize });

  const { data: usage, isLoading: usageIsLoading } = useGetToggleUsageQuery(
    {
      organizationId: orgId,
      projectId: toggle?.project.id || '',
      toggleKey: toggle?.key || '',
      pageNum: qsState.pageNum,
      pageSize: qsState.pageSize,
    },
    { skip: isToggleLoading },
  );

  const hasPagination = useMemo(() => {
    return showPagination && usage?.total && usage.total > qsState.pageSize;
  }, [showPagination, qsState.pageSize, usage]);

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const columnConfig = useMemo(
    () => [
      {
        heading: '',
        dataKey: 'meta',
        width: 130,
        render: renderTimestamp,
      },
      {
        heading: 'Value',
        dataKey: 'data',
        render: renderReturnValue,
      },
      {
        heading: 'Reason',
        dataKey: 'data',
        render: renderReason,
      },
      {
        heading: 'App',
        dataKey: 'context',
        render: renderAppName,
      },
      {
        heading: 'Environment',
        dataKey: 'context',
        render: renderEnvironment,
      },
      {
        heading: 'Evaluation Context',
        dataKey: 'context',
        render: (_cell: any, row: any) => <ToggleUsageDrawer display="context" evaluationEvent={row} />,
      },
    ],
    [],
  );

  const isLoading = useMemo(() => usageIsLoading || isToggleLoading, [usageIsLoading, isToggleLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && usage && usage.total > 0) {
    return (
      <>
        <Box fontSize="xs">
          <Table
            rowKey="id"
            columns={columnConfig}
            rows={usage.data}
            isScrollable={{
              x: true,
              y: false,
            }}
          />
          {hasPagination && (
            <Box padding="2xl" borderWidth="sm 0 0 0">
              <Pagination
                activePage={qsState.pageNum}
                itemsPerPage={qsState.pageSize}
                onChange={handlePageNumberChange}
                totalItemsCount={usage?.total ?? 0}
                numberOfPagesDisplayed={3}
                isCompact
                arePagesVisible
              />
            </Box>
          )}
        </Box>
      </>
    );
  }

  return <EmptyList title="No usage data" description="This feature toggle has not been used" />;
};

const renderTimestamp = (cell: any) => {
  return cell.createdAt ? (
    <Box alignItems="flex-start">{timeAgo(cell.createdAt)}</Box>
  ) : (
    <Box fontSize="sm">never</Box>
  );
};

const renderReason = (cell: any) => cell.toggle.reason || cell.toggle.errorMessage;

const renderReturnValue = (cell: any) => <code>{cell.toggle.value.toString()}</code>;

const renderAppName = (cell: any) => cell.application;

const renderEnvironment = (cell: any) => (
  <Box
    display="inline-flex"
    textAlign="left"
    borderWidth="0"
    height="100"
    padding="sm"
    background="secondary"
    direction="row"
    gap="xs"
    alignItems="center"
    radius="md"
  >
    {cell.environment.color && <ColorDot color={cell.environment.color} />}
    <Box fontFamily="monospace" flex="auto">
      {cell.environment}
    </Box>
  </Box>
);

export default ToggleUsageList;
