import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  FormikSelectInput,
  Modal,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Project } from '../../services/projects';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Formik, Field, Form } from 'formik';
import { ApiError } from '../ApiError';
import { SelectMember } from '../members/SelectOrCreateMembers';
import { useCreateMemberMutation } from '../../services/member';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useGiveAccessMutation } from '../../services/access';
import { OrganizationRoles, ProjectRoles, Role } from '../../types/roles';
import { Entity } from '../../types/access';
import { SelectApiKeyMember } from '../members/SelectApiKeyMember';
import { SelectTeam } from '../teams/SelectTeam';

const giveMemberAccessSchema = yup.object().shape({
  assignment: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string().required('Please select a member'),
      __isNew__: yup.boolean(),
    })
    .required('Please select a member'),
  roles: yup
    .array(
      yup.object().shape({
        label: yup.string(),
        value: yup.string().required(),
      }),
    )
    .required('Roles are required'),
});

type GiveMemberAccessSchema = InferType<typeof giveMemberAccessSchema>;

export enum AssignmentTypes {
  Member = 'Member',
  APIKey = 'APIKey',
  Team = 'Team',
}

interface GiveAccessProps {
  project: Project;
  entity: Entity;
  allowedAssignmentTypes?: AssignmentTypes[];
}

const DefaultAllowedAssignmentTypes = [AssignmentTypes.Member, AssignmentTypes.APIKey, AssignmentTypes.Team];

export default function GiveAccess({
  project,
  entity,
  allowedAssignmentTypes = DefaultAllowedAssignmentTypes,
}: GiveAccessProps) {
  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);
  const canInviteMembersToOrganization = ability.can('create', EntityNames.Member);
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [giveAccess, { error, isLoading }] = useGiveAccessMutation();
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  const [assignmentType, setAssignmentType] = useState<AssignmentTypes>(AssignmentTypes.Member);

  const handleGiveAccessTo = useCallback(
    (type: AssignmentTypes) => {
      setAssignmentType(type);
      handleOpen();
    },
    [handleOpen],
  );

  const errorMessages = {
    default: 'Failed to invite member',
  };

  const [roleOptions, setRoleOptions] = useState<{ label: string; value: any }[]>([] as any);

  useMemo(() => {
    switch (entity.type) {
      case 'Project':
        setRoleOptions([
          {
            label: 'Project Viewer',
            value: ProjectRoles.ProjectViewer,
          },
          {
            label: 'Project Collaborator',
            value: ProjectRoles.ProjectCollaborator,
          },
          {
            label: 'Project Owner',
            value: ProjectRoles.ProjectOwner,
          },
        ]);
        break;
      case 'Organization':
        setRoleOptions([
          {
            label: 'Admin',
            value: OrganizationRoles.OrganizationAdmin,
          },
          {
            label: 'Member',
            value: OrganizationRoles.OrganizationMember,
          },
        ]);
        break;
      default:
        setRoleOptions([]);
        break;
    }
  }, [entity.type]);

  const handleOnSubmit = useCallback(
    async (values: GiveMemberAccessSchema) => {
      let assignmentId = values.assignment.value;

      if (values.assignment.__isNew__) {
        const createdMember = await createMember({
          email: values.assignment.value,
          organizationId: project.organization.id,
        });
        if (createdMember.data && !createError) assignmentId = createdMember.data?.id;
      }
      const { error } = await giveAccess({
        entity: { type: 'Project', id: project.id },
        assignment: { type: assignmentType === AssignmentTypes.Team ? 'Team' : 'Member', id: assignmentId },
        organizationId: project.organization.id,
        roles: (values.roles || []).map((r) => r.value as Role),
      });
      if (!error) {
        toast.success('Access Given', { duration: 5000 });
        handleClose();
      }
    },
    [assignmentType, createError, createMember, giveAccess, handleClose, project.id, project.organization.id],
  );

  if (!canUpdateProject) return null;

  return (
    <Box alignItems="flex-start">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="primary" size="sm" iconSuffix="caret-down" fullWidth>
            Give Access
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" side="bottom">
          <DropdownMenuGroup>
            {allowedAssignmentTypes.includes(AssignmentTypes.Member) && (
              <DropdownMenuItem onSelect={() => handleGiveAccessTo(AssignmentTypes.Member)}>Member</DropdownMenuItem>
            )}
            {allowedAssignmentTypes.includes(AssignmentTypes.Team) && (
              <DropdownMenuItem onSelect={() => handleGiveAccessTo(AssignmentTypes.Team)}>Team</DropdownMenuItem>
            )}
            {allowedAssignmentTypes.includes(AssignmentTypes.APIKey) && (
              <DropdownMenuItem onSelect={() => handleGiveAccessTo(AssignmentTypes.APIKey)}>
                API Key
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal ariaLabelledBy="giveAccessTitle" isOpen={isOpen} onDismiss={handleClose} maxWidth="9xl">
        <Formik
          enableReinitialize
          initialValues={{ assignment: { value: '', label: '' }, roles: [] }}
          validationSchema={giveMemberAccessSchema}
          onSubmit={handleOnSubmit}
          validateOnBlur={false}
        >
          {({ isSubmitting, errors }) => (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header id="giveAccessTitle" title={`${project.name} Access`} onDismiss={handleClose} />
                <Modal.Body gap="2xl" overflow="visible">
                  <Box as="p" color="secondary" fontSize="sm">
                    Select the {assignmentType}(s) and their role to give access to this project
                  </Box>
                  {assignmentType === AssignmentTypes.Member && (
                    <SelectMember
                      label="Member(s)"
                      name="assignment"
                      error={errors.assignment?.value}
                      isMulti={false}
                      isCreatable={canInviteMembersToOrganization}
                      portalTarget={document.body}
                      includeApiKeys={false}
                    />
                  )}
                  {assignmentType === AssignmentTypes.APIKey && (
                    <SelectApiKeyMember
                      label="API Key"
                      name="assignment"
                      error={errors.assignment?.value}
                      isMulti={false}
                      portalTarget={document.body}
                    />
                  )}
                  {assignmentType === AssignmentTypes.Team && (
                    <SelectTeam
                      label="Team"
                      name="assignment"
                      error={errors.assignment?.value}
                      isMulti={false}
                      portalTarget={document.body}
                    />
                  )}
                  <Field
                    label="Roles"
                    name="roles"
                    id="roles"
                    options={roleOptions}
                    component={FormikSelectInput}
                    error={errors.roles}
                    isMulti
                    isDisabled={isLoading}
                    menuPortalTarget={document.body}
                  />
                  {error ? <ApiError error={error} customMessages={errorMessages} /> : null}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose} isDisabled={isSubmitting} shadow="sm">
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" isLoading={isSubmitting} shadow="sm">
                    Give Access
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>
  );
}
