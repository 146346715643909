import { Box } from '@hyphen/hyphen-components';
import { ReactSVG } from 'react-svg';
import { INTEGRATION_ICON_MAP, INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { IntegrationType } from '../../../types/integrations';

const IntegrationIcon = ({ type, isMobile }: { type: IntegrationType; isMobile: boolean }) => (
  <Box direction="row" alignItems="center" gap="xs">
    <Box
      as="span"
      width="xl"
      height="xl"
      minWidth="xl"
      minHeight="xl"
      background="primary"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
    >
      <ReactSVG
        src={INTEGRATION_ICON_MAP[type]}
        beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%; display: block;')}
      />
    </Box>
    {!isMobile && INTEGRATION_NAME_MAP[type]}
  </Box>
);

export default IntegrationIcon;
