import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerHeader,
  DrawerProvider,
  DrawerTitle,
  DrawerTrigger,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';

import PageHeader from '../../../components/PageHeader';
import { Route, Routes } from 'react-router-dom';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import Error from '../../../Error';
import { ToggleStatus } from '../../../components/toggles/ToggleStatus';
import { useToggle } from '../../../providers/ToggleProvider';
import ToggleSettings from './ToggleSettings';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import ToggleUsage from './ToggleUsage';
import { EventList } from '../../../components/events/EventList';
import { EventReferenceType } from '../../../types/events';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { Skeleton } from '../../../components/common/Skeleton';
import { useMemo } from 'react';
import { FeatureToggleGuide } from './FeatureToggleGuide';

const ToggleDetailContainer = () => {
  const { toggle, error, isLoading } = useToggle();
  const isMobile = useIsMobile();
  const ability = useOrganizationAbilityContext();

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        description: <Skeleton height="24px" width="100px" />,
        favoriteId: '',
      };
    }
    return {
      title: toggle?.key,
      description: (
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ base: 'flex-start', tablet: 'center' }}
          gap="sm"
        >
          <Box
            as="span"
            fontFamily="monospace"
            fontSize="sm"
            display="inline-block"
            padding="xs"
            color="secondary"
            background="secondary"
          >
            {toggle?.type}
          </Box>
          {toggle?.description}
        </Box>
      ),
      favoriteId: toggle?.id,
    };
  }, [isLoading, isMobile, toggle]);

  if (!isLoading && error) return <Error />;

  const canUpdateToggle = toggle ? ability.can('update', toggle) : false;

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>{toggle?.key}</title>
      </Helmet>
      <BreadCrumbPortal entity={toggle} />
      <PageHeader {...pageHeaderProps} />
      <DrawerProvider className="display-flex w-100 flex-direction-column g-2xl g-4xl-tablet">
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          gap="2xl"
          justifyContent="space-between"
          alignItems={{ base: 'stretch', tablet: 'center' }}
        >
          <TabNav>
            <TabNavLink to="">Status</TabNavLink>
            <TabNavLink to="events">Events</TabNavLink>
            <TabNavLink to="usage">Usage</TabNavLink>
            {canUpdateToggle && <TabNavLink to="settings">Settings</TabNavLink>}
          </TabNav>
          {!isMobile && (
            <DrawerTrigger asChild>
              <Button variant="secondary" iconPrefix="c-question">
                SDK Setup Guide
              </Button>
            </DrawerTrigger>
          )}
        </Box>
        <Routes>
          <Route
            path="/events"
            element={
              <EventList
                references={[
                  {
                    id: toggle?.key ?? '',
                    type: EventReferenceType.Toggle,
                  },
                  {
                    id: toggle?.project.id ?? '',
                    type: EventReferenceType.Project,
                  },
                ]}
              />
            }
          />
          <Route path="/usage" element={<ToggleUsage />} />
          <Route path="/settings" element={<ToggleSettings />} />
          <Route path="/" element={<ToggleStatus />} />
        </Routes>
        <Drawer ariaLabel="Feature Toggle SDK Usage Guide" width={isMobile ? '90%' : '50%'}>
          <DrawerHeader>
            <DrawerTitle>Toggle SDK Quick Start Guide</DrawerTitle>
            <DrawerCloseButton />
          </DrawerHeader>
          <Box display="block" maxWidth="10xl">
            <FeatureToggleGuide />
          </Box>
        </Drawer>
      </DrawerProvider>
    </ScrollableMainLayout>
  );
};

export default ToggleDetailContainer;
