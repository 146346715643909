import { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarRail,
  toast,
  useSidebar,
} from '@hyphen/hyphen-components';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

// Components
import GetStarted from '../GetStarted';

// Hooks and Utilities
import { useAuth } from '../auth/authProvider';
import { useOrganizationsList } from '../../providers/OrganizationsListProvider';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';
import OrganizationSwitcher from '../OrganizationSwitcher';
import UserMenu from '../UserMenu';
import { IconName } from '@hyphen/hyphen-components/dist/types';
import { OrganizationMemberProvider, useOrganizationMember } from '../../providers/OrganizationMemberProvider';
import { getFavoriteUrl } from '../../utils/getFavoriteUrl';
import { Member } from '../../types/members';
import SidebarItem, { ISidebarItem } from './SidebarItem';
import FavoriteItem from './FavoriteItem';
import { useGetProjectsQuery } from '../../services/projects';
import { useUpdateFavoriteMutation } from '../../services/member';

const AppSidebar: React.FC = () => {
  const { id: orgId, organization = {} as Organization } = useOrganization();
  const { organizations = [] } = useOrganizationsList();
  const { member = {} as Member } = useOrganizationMember();
  const ability = useOrganizationAbilityContext();
  const canManageOrganizationIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);
  const canReadEvents = ability.can('read', EntityNames.Event);
  const canViewApiKeys = ability.can('read', EntityNames.ApiKey);
  const canViewMembers = ability.can('read', EntityNames.Member);

  const { data: projectsData } = useGetProjectsQuery({
    organizationId: orgId,
  });

  const { isMobile, setOpenMobile } = useSidebar();

  const { logout, user } = useAuth();

  const projectsItems = useMemo(() => {
    if (!projectsData) return [];

    return projectsData.data.map((project) => ({
      label: project.name,
      link: `/${orgId}/${project.alternateId}`,
      key: project.id,
    }));
  }, [orgId, projectsData]);

  const SIDEBAR_ITEMS = useMemo<ISidebarItem[]>(
    () => [
      { label: 'Dashboard', icon: 'dashboard', link: `/${orgId}`, disabled: false },
      ...(projectsItems.length > 0
        ? [
            {
              label: 'Projects',
              icon: 'blocks' as IconName,
              link: `/${orgId}/projects`,
              disabled: false,
              items: projectsItems,
            },
          ]
        : []),
      { label: 'Teams', icon: 'users', link: `/${orgId}/teams`, disabled: false },
      { label: 'Link', icon: 'logo-link', link: `/${orgId}/link`, disabled: false },
      { label: 'ENV', icon: 'logo-env', link: `/${orgId}/env`, disabled: false },
      { label: 'Toggle', icon: 'logo-toggle', link: `/${orgId}/toggles`, disabled: false },
      ...(canManageOrganizationIntegrations
        ? [
            {
              label: 'Integrations',
              icon: 'integrations' as IconName,
              link: `/${orgId}/integrations`,
              disabled: false,
            },
          ]
        : []),
      ...(canReadEvents
        ? [
            {
              label: 'Events',
              icon: 'document' as IconName,
              link: `/${orgId}/events`,
              disabled: false,
            },
          ]
        : []),
      {
        label: 'Settings',
        icon: 'settings',
        disabled: false,
        link: `/${orgId}/settings`,
        items: [
          { label: 'General', link: `/${orgId}/settings`, key: 'general' },
          { label: 'Domains', link: `/${orgId}/settings/domains`, key: 'domains' },
          { label: 'Environments', link: `/${orgId}/settings/environments`, key: 'environments' },
          ...(canViewApiKeys ? [{ label: 'API Keys', link: `/${orgId}/settings/api-keys`, key: 'apikeys' }] : []),
          ...(canViewMembers ? [{ label: 'Members', link: `/${orgId}/settings/members`, key: 'members' }] : []),
          { label: 'Billing', link: `/${orgId}/settings/billing`, key: 'billing' },
        ],
      },
    ],
    [orgId, projectsItems, canManageOrganizationIntegrations, canReadEvents, canViewApiKeys, canViewMembers],
  );

  const SIDEBAR_FAVORITES = useMemo(
    () =>
      Array.isArray(member?.favorites) && member.favorites.length > 0
        ? member.favorites.map((favorite) => ({
            link: getFavoriteUrl(orgId, favorite),
            favorite: favorite,
          }))
        : [],
    [member.favorites, orgId],
  );

  const [favorites, setFavorites] = useState(SIDEBAR_FAVORITES);
  const [updateFavorite] = useUpdateFavoriteMutation();

  useEffect(() => {
    setFavorites(SIDEBAR_FAVORITES);
  }, [SIDEBAR_FAVORITES, member.favorites]);

  const handleMenuItemClick = () => {
    if (isMobile) {
      setOpenMobile(false);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldFavorites = favorites;

      const updatedFavorites = arrayMove(
        favorites,
        favorites.findIndex((item) => item.favorite.id === active.id), // old index
        favorites.findIndex((item) => item.favorite.id === over.id), // new index
      );

      setFavorites(updatedFavorites);

      try {
        const newIndex = updatedFavorites.findIndex((item) => item.favorite.id === active.id);
        await updateFavorite({
          memberId: member.id,
          organizationId: orgId,
          favoriteId: active.id as string,
          body: { index: newIndex },
        });
      } catch (error) {
        toast.error('Update favorites failed.');
        setFavorites(oldFavorites); // Revert to the old order
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const showFavorites = SIDEBAR_FAVORITES.length > 0;

  return (
    <OrganizationMemberProvider>
      <Sidebar side="left" collapsible="offcanvas">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <OrganizationSwitcher
                organizations={organizations}
                currentOrg={organization}
                onOrgSelect={handleMenuItemClick}
              />
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent className="scroll-bar-thin">
          {showFavorites && (
            <SidebarGroup>
              <SidebarGroupLabel>Favorites</SidebarGroupLabel>
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                  items={favorites.map((item) => item.favorite.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <SidebarMenu>
                    {favorites.map(({ favorite, link }) => (
                      <FavoriteItem
                        key={favorite.id}
                        favorite={favorite}
                        link={link}
                        onClick={handleMenuItemClick}
                      />
                    ))}
                  </SidebarMenu>
                </SortableContext>
              </DndContext>
            </SidebarGroup>
          )}
          <SidebarGroup>
            {showFavorites && <SidebarGroupLabel>Platform</SidebarGroupLabel>}
            <SidebarMenu>
              {SIDEBAR_ITEMS.map(({ icon, label, ...restProps }) => (
                <SidebarItem
                  key={label}
                  label={label}
                  icon={icon as IconName}
                  onClick={handleMenuItemClick}
                  {...restProps}
                />
              ))}
            </SidebarMenu>
          </SidebarGroup>
          {canManageOrganizationIntegrations && (
            <SidebarGroup>
              <Box padding="0 lg">
                <GetStarted organizationId={orgId} />
              </Box>
            </SidebarGroup>
          )}
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              {user && organizations.length && (
                <UserMenu
                  user={user}
                  organizations={organizations}
                  organizationId={orgId}
                  logout={logout}
                  onSelect={handleMenuItemClick}
                />
              )}
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        {!isMobile && <SidebarRail />}
      </Sidebar>
    </OrganizationMemberProvider>
  );
};

export default AppSidebar;
