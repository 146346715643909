import '@hyphen/hyphen-components/dist/css/reset.css';
import '@hyphen/hyphen-components/dist/css/utilities.css';
import '@hyphen/hyphen-components/dist/css/variables.css';
import '@hyphen/hyphen-components/dist/css/index.css';
import '@hyphen/hyphen-components/dist/css/fonts.css';
import './App.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { HyphenProvider } from '@hyphen/openfeature-web-provider';

import { AuthProvider } from './components/auth/authProvider';
import Error from './Error';
import FocusedLayout from './layouts/FocusedLayout';
import { OrganizationBase } from './pages/OrganizationBase';
import { OrganizationList } from './pages/OrganizationList';
import { OrganizationsListProvider } from './providers/OrganizationsListProvider';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ResponsiveProvider, ThemeProvider, ToastContainer, TooltipProvider } from '@hyphen/hyphen-components';
import { Profile } from './pages/Profile';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { IntegrationSetup } from './pages/Integrations/IntegrationSetup';
import { OrganizationCreate } from './pages/GetStarted/OrganizationCreate';
import { OrganizationSubscription } from './pages/GetStarted/OrganizationSubscription';
import { Helmet } from 'react-helmet';
import TagManager from '@sooro-io/react-gtm-module';
import { ErrorPage } from './pages/Error';
import RegistrationLayout from './layouts/RegistrationLayout';
import Billing from './pages/Billing';

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    ...(process.env.REACT_APP_GTM_AUTH ? { auth: process.env.REACT_APP_GTM_AUTH } : {}),
    ...(process.env.REACT_APP_GTM_ENV ? { preview: process.env.REACT_APP_GTM_ENV } : {}),
  };

  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);



export const routes = {
  base: '/',
  getStarted: 'get-started',
  integrations: 'integrations',
  integrationSetup: 'integrations/setup/:integrationType',
  profile: 'profile',
  billing: 'billing',
  error: 'error',
};

const router = createBrowserRouter([
  {
    path: `${routes.base}`,
    errorElement: <Error />,
    children: [
      {
        element: <RegistrationLayout />,
        children: [
          {
            path: `${routes.getStarted}`,
            element: <OrganizationCreate />,
          },
          {
            path: `${routes.getStarted}/:orgId`,
            element: <OrganizationSubscription />,
          },
          {
            index: true,
            element: <OrganizationList />,
          },
        ],
      },
      {
        element: <FocusedLayout />,
        children: [
          {
            path: `${routes.integrationSetup}`,
            element: <IntegrationSetup />,
          },
        ],
      },
      {
        children: [
          {
            path: `${routes.profile}/*`,
            element: <Profile />,
          },
          {
            path: `:id/*`,
            element: <OrganizationBase />,
          },
        ],
      },
      {
        children: [
          {
            path: `${routes.billing}/:billingAccountId/*`,
            element: <Billing />,
          },
        ],
      },
      {
        path: routes.error,
        element: <ErrorPage />,
      },
    ],
  },
]);

(async (): Promise<void> => {

  await OpenFeature.setProviderAndWait(new HyphenProvider('public_b3JnXzY2ODU3NWMwZTE2OWNkZTk3NGE1Yzc2YTpwcm9qXzY3MGQ1M2Q4M2ViZDdiZmJkN2YxZjUwMTplb3hJczd2RUlVT3FmRzdwUXJmMg==', {
    application: 'app',
    environment: process.env.REACT_APP_HYPHEN_ENVIRONMENT || 'Development',
  }));

  root.render(
    <Provider store={store}>
      <ThemeProvider storageKey="engine-ui-theme">
        <OpenFeatureProvider>
          <AuthProvider>
            <Helmet titleTemplate="%s | Hyphen"></Helmet>
            <OrganizationsListProvider>
              <ResponsiveProvider>
                <TooltipProvider delayDuration={150}>
                  <ToastContainer />
                  <RouterProvider router={router} />
                </TooltipProvider>
              </ResponsiveProvider>
            </OrganizationsListProvider>
          </AuthProvider>
        </OpenFeatureProvider>
      </ThemeProvider>
    </Provider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
