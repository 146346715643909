import { ListResponse } from './types';
import { api } from './api';
import {
  Connection,
  ConnectionAccess,
  GetConnectionsAccessQueryParams,
  GetConnectionsQueryParams,
  NewConnection,
} from '../types/connections';

export const connectionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConnections: build.query<ListResponse<Connection>, GetConnectionsQueryParams>({
      query: ({ organizationId, entityIds, pageSize, pageNum, types, integrationTypes }) => {
        const params = new URLSearchParams();
        if (entityIds && entityIds.length) {
          entityIds.forEach((entityId) => params.append('entityIds', entityId));
        }
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (types && types.length) {
          types.forEach((type) => params.append('types', type));
        }
        if (integrationTypes && integrationTypes.length) {
          integrationTypes.forEach((type) => params.append('integrationTypes', type));
        }
        return {
          url: `api/organizations/${organizationId}/integrations/connections?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { organizationId }) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: 'Connections' as const, id })),
            { type: 'Connections', id: `${organizationId}-LIST` },
          ]
          : [],
    }),
    getConnection: build.query<Connection, { organizationId: string; integrationId: string; connectionId: string }>({
      query: ({ organizationId, integrationId, connectionId }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections/${connectionId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { connectionId }) =>
        result ? [{ type: 'Connections', id: connectionId }] : [],
    }),
    createConnection: build.mutation<
      Connection,
      { organizationId: string; integrationId: string; connection: NewConnection }
    >({
      query: ({ organizationId, integrationId, connection }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections`,
        method: 'POST',
        body: connection,
      }),
      invalidatesTags: (_result, _error, { organizationId }) => [
        { type: 'Connections', id: `${organizationId}-LIST` },
      ],
    }),
    deleteConnection: build.mutation<void, { organizationId: string; integrationId: string; connectionId: string }>({
      query: ({ organizationId, integrationId, connectionId }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections/${connectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { organizationId, connectionId }) => [
        { type: 'Connections', id: connectionId },
        { type: 'Connections', id: `${organizationId}-LIST` },
      ],
    }),
    verifyConnection: build.mutation<
      void,
      { organizationId: string; integrationId: string; connectionId: string; code: string }
    >({
      query: ({ organizationId, integrationId, connectionId, code }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections/${connectionId}/verify`,
        method: 'POST',
        body: { code },
      }),
      invalidatesTags: (_result, _error, { organizationId, connectionId }) => [
        { type: 'Connections', id: connectionId },
        { type: 'Connections', id: `${organizationId}-LIST` },
      ],
    }),
    getConnectionsAccess: build.query<ListResponse<ConnectionAccess>, GetConnectionsAccessQueryParams>({
      query: ({
        organizationId,
        entityConnectionIds,
        assignmentConnectionIds,
        entityIds,
        assignmentIds,
        pageNum,
        pageSize,
      }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (entityConnectionIds && entityConnectionIds.length) {
          entityConnectionIds.forEach((entityConnectionId) =>
            params.append('entityConnectionIds', entityConnectionId),
          );
        }
        if (assignmentConnectionIds && assignmentConnectionIds.length) {
          assignmentConnectionIds.forEach((assignmentConnectionId) =>
            params.append('assignmentConnectionIds', assignmentConnectionId),
          );
        }
        if (entityIds && entityIds.length) {
          entityIds.forEach((entityId) => params.append('entityIds', entityId));
        }
        if (assignmentIds && assignmentIds.length) {
          assignmentIds.forEach((assignmentId) => params.append('assignmentIds', assignmentId));
        }
        return {
          url: `api/organizations/${organizationId}/integrations/connections/access?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { organizationId }) =>
        result ? [{ type: 'IntegrationsConnectionsAccess', id: organizationId }] : [],
    }),
  }),
});

export const {
  useGetConnectionsQuery,
  useGetConnectionQuery,
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
  useVerifyConnectionMutation,
  useGetConnectionsAccessQuery,
} = connectionsApi;
