import { Project } from '../services/projects';
import { ParsedCondition } from '../utils/parseJsonLogic';
import { ProjectReference } from './projects';

export type Toggle = {
  id: string;
  key: string;
  description?: string;
  tags?: string[];
  type: ToggleReturnType;
  targets: ToggleTarget[];
  defaultValue: string | number | boolean | object;
  project: ProjectReference;
  organization: {
    id: string;
  };
  meta?: {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
  };
};

export interface ToggleTarget {
  logic: string;
  value: string | number | boolean;
  id: string;
  parsedLogic: ParsedCondition[];
}

export enum ToggleType {
  Boolean = 'boolean',
  String = 'string',
  Number = 'number',
  Object = 'object',
}

export type ToggleReturnType = ToggleType.String | ToggleType.Boolean | ToggleType.Number | ToggleType.Object;

export interface ToggleUsageSummary {
  count: number;
  environments: Array<{
    name: string;
    count: number;
  }>;
}

export interface ToggleEvaluationResult {
  key: string;
  type: ToggleType;
  value: string | number | boolean | object;
  reason?: string;
  errorMessage?: string;
}
export interface ToggleEvaluationUserContext {
  id: string;
  email: string;
  name: string;
  customAttributes?: Record<string, string>;
}
export interface ToggleEvaluationContext {
  targetingKey: string;
  ipAddress?: string;
  application: string;
  environment: string;
  user: ToggleEvaluationUserContext;
  customAttributes?: Record<string, string>;
}

export interface ToggleUsage {
  context: ToggleEvaluationContext;
  data: { toggle: ToggleEvaluationResult };
  meta: { createdAt: string };
}

export interface ToggleSummary {
  project: ProjectReference;
  toggles: Record<
    string,
    {
      count: number;
      targets: ToggleTarget[];
      environments: { name: string; count: number }[];
      type: ToggleReturnType;
      meta: {
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    }
  >;
}

export interface ToggleDailyEvaluations {
  projects: Array<ToggleDailyEvaluationsProject>;
}

export interface ToggleDailyEvaluationsProject {
  project: Project;
  evaluations: Array<ToggleDailyEvaluationsEnvironment>;
  total: number;
}

export interface ToggleDailyEvaluationsEnvironment {
  environment: string;
  total: number;
  dailyUsage: {
    date: string;
    count: number;
  }[];
}

export interface Foo {
  projects: {
    project: Project;
    evaluations: ToggleDailyUsage;
    usage: ToggleDailyUsage;
  }[];
}

export interface ToggleDailyUsage {
  counts: {
    total: number;
    environments: Record<string, number>;
  };
  dailyUsage: {
    date: string;
    counts: {
      total: number;
      environments: Record<string, number>;
    };
  }[];
}
