import { Box, Card, toast } from '@hyphen/hyphen-components';
import ClickToCopy from '../common/ClickToCopy';
import Targets from './Targets';
import LastEvent from './LastEvent';
import { useToggle } from '../../providers/ToggleProvider';
import DailyUsageCard from './DailyUsageCard';
import ToggleUsageList from './ToggleUsageList';
import { EventReferenceType, EventType } from '../../types/events';
import InfoBox from '../common/InfoBox';
import { useUpdateToggleTargetsMutation } from '../../services/toggle';
import { ToggleTarget } from '../../types/toggle';
import { TOGGLE_TEXT } from '../../constants/toggle';
import { ApiError } from '../ApiError';
import CreatedBy from '../common/CreatedBy';

export const ToggleStatus = () => {
  const { toggle, usage, isLoading, error } = useToggle();
  const [updateToggleTargets] = useUpdateToggleTargetsMutation();

  const toggleReferences = toggle && [
    { id: toggle.key, type: EventReferenceType.Toggle },
    { id: toggle.project.id, type: EventReferenceType.Project },
  ];

  const handleDragEnd = async (newTargetOrder: ToggleTarget[]) => {
    try {
      if (toggle) {
        const { error, data } = await updateToggleTargets({
          organizationId: toggle.organization.id,
          projectId: toggle.project.alternateId,
          toggleKey: toggle.key,
          body: { targets: newTargetOrder },
        });

        if (!error && data) {
          toast.success('Order updated');
        } else {
          toast.error('Order update failed');
        }
      }
    } catch (err) {
      throw err;
    }
  };

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <Box gap="4xl">
      <Card className="fade-in">
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          fontSize="xs"
          background="tertiary"
          style={{ gap: '1px' }}
        >
          <InfoBox
            title="Key ID"
            helpText={TOGGLE_TEXT.keyIdDescription}
            isLoading={isLoading || !toggle}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          >
            {toggle && <ClickToCopy fontSize="xs" text={toggle?.key} />}
          </InfoBox>
          <InfoBox
            title="Created by"
            isLoading={isLoading || !toggle}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
          >
            <CreatedBy
              organizationId={toggle?.organization.id || ''}
              memberId={toggle?.meta?.createdBy || ''}
              createdAt={toggle?.meta?.createdAt || ''}
            />
          </InfoBox>
          <InfoBox
            title="Last event"
            isLoading={isLoading || !toggle}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            gap="sm"
          >
            <LastEvent
              references={toggleReferences}
              eventTypes={[EventType.CreateToggle, EventType.UpdateToggle, EventType.DeleteToggle]}
            />
          </InfoBox>
        </Box>
      </Card>
      <Card position="relative">
        <DailyUsageCard />
        <Card.Section padding="0">
          {(usage?.counts?.total ?? 0) > 0 && <ToggleUsageList showPagination={false} pageSize={5} />}
        </Card.Section>
      </Card>
      <Targets entity={toggle} isLoading={isLoading} onDragEnd={handleDragEnd} />
    </Box>
  );
};
