import { Box, Card } from '@hyphen/hyphen-components';
import { useSegment } from '../../providers/SegmentProvider';
import ClickToCopy from '../common/ClickToCopy';
import LastEvent from '../toggles/LastEvent';
import { EventReferenceType, EventType } from '../../types/events';
import { SEGMENT_TEXT } from '../../constants/segments';
import InfoBox from '../common/InfoBox';
import SegmentUsageList from './SegmentUsageList';
import SegmentRules from './SegmentRules';

function SegmentDetails() {
  const { segment, isLoading } = useSegment();

  return (
    <Box gap="4xl">
      <Card className="fade-in">
        <Box direction={{ base: 'column', tablet: 'row' }} fontSize="sm">
          <InfoBox
            title="Segment Key"
            helpText={SEGMENT_TEXT.segmentKeyDescription}
            isLoading={isLoading || !segment}
            skeletonWidth="120px"
            skeletonHeight="30px"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
          >
            {segment && <ClickToCopy fontSize="xs" text={segment?.alternateId} />}
          </InfoBox>
          <Box
            flex="auto"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            alignItems={{ base: 'center', desktop: 'flex-start' }}
            direction={{ base: 'row', desktop: 'column' }}
            gap="lg"
          >
            {segment && (
              <LastEvent
                references={[
                  {
                    id: segment.id,
                    type: EventReferenceType.Segment,
                  },
                  {
                    id: segment.project.id,
                    type: EventReferenceType.Project,
                  },
                ]}
                eventTypes={[EventType.CreateSegment, EventType.UpdateSegment, EventType.DeleteSegment]}
              />
            )}
          </Box>
        </Box>
      </Card>

      <Box gap="4xl" direction={{ base: 'column', hd: 'row' }} alignItems={{ hd: 'flex-start' }}>
        <Box width={{ hd: '30' }} style={{ flexShrink: 0 }} maxWidth={{ hd: '9xl' }}>
          <SegmentUsageList />
        </Box>
        <SegmentRules />
      </Box>
    </Box>
  );
}

export default SegmentDetails;
