import { Box, Card, Pagination, Spinner, Table } from '@hyphen/hyphen-components';
import { Project, useGetAccessQuery } from '../../services/projects';
import useQueryStringState from '../../hooks/useQueryStringState';
import { useCallback } from 'react';
import { RoleDisplayName } from '../../utils/roles';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import RevokeAccess from './RevokeAccess';
import BadgeTooltip from '../BadgeTooltip';
import { ApiError } from '../ApiError';
import GiveAccess from '../access/GiveAccess';

export default function ProjectAccessList({ project }: { project: Project }) {
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const {
    data: access,
    isLoading,
    error,
  } = useGetAccessQuery({
    projectId: project.id,
    organizationId: project.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);

  const accessWithType = (access?.data || [])
    .map((item: any) => ({
      ...item,
      accessType: item.entity.type === 'Project' && item.entity.id === '*' ? 'inherited' : 'direct',
    }))
    .sort((a: any, b: any) => (a.accessType === 'direct' ? -1 : 1));

  const columnConfig = [
    {
      heading: 'Access Type',
      width: 100,
      dataKey: 'accessType',
      render: (accessType: any) =>
        accessType === 'inherited' ? (
          <BadgeTooltip
            size="sm"
            variant="light-grey"
            message="global"
            tooltipContent="This access is applied to all Projects and set at the organization level"
          />
        ) : (
          <BadgeTooltip
            size="sm"
            variant="blue"
            message="direct"
            tooltipContent="Access was given directly to this project"
          />
        ),
    },
    {
      heading: 'Name',
      dataKey: 'assignment',
      render: (assignment: any) => {
        if (assignment.type === 'Team') return <>{assignment.name} (Team)</>;
        else return <>{assignment.name}</>;
      },
    },
    {
      heading: 'Roles',
      dataKey: 'roles',
      render: (roles: any) => (roles || []).map(RoleDisplayName).join(', '),
    },
  ];

  if (canUpdateProject) {
    columnConfig.push({
      // @ts-ignore
      render: (_cell: any, row: any) =>
        row.accessType === 'direct' ? (
          <Box alignItems="flex-end">
            <RevokeAccess
              name={row.assignment.name}
              projectId={project.id}
              accessId={row.id}
              organizationId={project.organization.id}
            />
          </Box>
        ) : null,
    });
  }

  if (!isLoading && error) return <ApiError error={error} />;

  return (
    <Card fontSize="sm">
      <Card.Header
        title="Access"
        description="Members and teams who have access to this project"
        borderWidth="0 0 sm 0"
        alignItems="center"
      >
        <GiveAccess project={project} entity={{ type: 'Project', id: project.id }} />
      </Card.Header>

      <Card.Section padding="lg 0" gap="lg">
        {isLoading ? (
          <Box padding="2xl">
            <Spinner />
          </Box>
        ) : (
          <>
            <Table
              rowKey="id"
              isLoading={isLoading}
              columns={columnConfig}
              rows={accessWithType || []}
              isScrollable={{ x: true, y: false }}
            />
            {access?.total && access?.total > qsState.pageSize ? (
              <Box padding="2xl 2xl md 2xl" borderWidth="sm 0 0 0">
                <Pagination
                  activePage={qsState.pageNum}
                  itemsPerPage={qsState.pageSize}
                  onChange={handlePageNumberChange}
                  totalItemsCount={access?.total ?? 0}
                  numberOfPagesDisplayed={3}
                  isCompact
                  arePagesVisible
                />
              </Box>
            ) : null}
          </>
        )}
      </Card.Section>
    </Card>
  );
}
