import { IntegrationType } from './integrations';

export interface EventsResponseData {
  alternateId: string;
  code: string;
  domain: string;
  domains: {
    id: string;
    domain: string;
  }[];
  email: string;
  entity?: {
    id: string;
    type: 'Team';
  };
  firstName: string;
  id: string;
  integrationType: IntegrationType;
  ip: string;
  lastName: string;
  name: string;
  organizationId: string;
  project: {
    alternateId: string;
    id: string;
    name: string;
  };
  status: number;
  userEmail: string;
  version: string;
  app: string;
  environment: string;
  userIpAddress: string;
  key: string;
}

type Location = {
  country: string;
  region: string;
  city: string;
  lat: number;
  lng: number;
  postalCode: string;
  timezone: string;
};

export interface EventsResponse {
  timestamp: string;
  type: string;
  actor: {
    id: string;
    name: string;
  };
  user: {
    name: string;
    id: string;
  };
  data: EventsResponseData;
  references: {
    id: string;
    type: string;
  }[];
  organization: {
    id: string;
    name: string;
  };
  member: {
    id: string;
    name: string;
  };
  requestId: string;
  location: Location;
}

export interface GetEventsBody {
  orgId: string;
  body: {
    pageSize: number;
    types?: EventType[];
    startDate?: string;
    endDate?: string;
    pageCursor?: string;
    references?: {
      id: string;
      type: EventReferenceType;
    }[];
    referenceOperation: ReferenceOperation;
  };
}

export enum ReferenceOperation {
  And = 'AND',
  Or = 'OR',
}

export enum EventType {
  // Resource based
  database = 'DATABASE',
  fileAccess = 'FILE-ACCESS',

  // Synthetic
  AddMember = 'ORGANIZATION.MEMBER.ADD',
  DeleteMember = 'ORGANIZATION.MEMBER.DELETE',
  CreateMember = 'ORGANIZATION.MEMBER.CREATE',
  UpdateMember = 'ORGANIZATION.MEMBER.UPDATE',

  // Teams
  createDistributionList = 'DISTRIBUTION-LIST.CREATE',
  CreateTeam = 'ORGANIZATION.TEAM.CREATE',
  DeleteTeam = 'ORGANIZATION.TEAM.DELETE',
  AddMemberToTeam = 'ORGANIZATION.TEAM.MEMBER.ADD',
  RemoveMemberFromTeam = 'ORGANIZATION.TEAM.MEMBER.REMOVE',

  // Organization
  CreateOrganization = 'ORGANIZATION.CREATE',
  UpdateOrganization = 'ORGANIZATION.UPDATE',

  // Billing Account
  CreateBillingAccount = 'BILLING-ACCOUNT.CREATE',
  AddBillingAccountOwner = 'BILLING-ACCOUNT.OWNER.ADD',
  RemoveBillingAccountOwner = 'BILLING-ACCOUNT.OWNER.REMOVE',

  // Organization integrations
  CreateOrganizationIntegration = 'ORGANIZATION.INTEGRATION.CREATE',
  DeleteOrganizationIntegration = 'ORGANIZATION.INTEGRATION.DELETE',

  // Link
  CreateLinkDomain = 'LINK.DOMAIN.CREATE',
  DeleteLinkDomain = 'LINK.DOMAIN.DELETE',
  UpdateLinkDomain = 'LINK.DOMAIN.UPDATE',
  CreateLinkCode = 'LINK.CODE.CREATE',
  DeleteLinkCode = 'LINK.CODE.DELETE',
  UpdateLinkCode = 'LINK.CODE.UPDATE',
  CreateLinkQrCode = 'LINK.QR.CREATE',
  DeleteLinkQrCode = 'LINK.QR.DELETE',

  // ENV
  CreateEnvData = 'ENV.CREATE',
  DeleteEnvData = 'ENV.DELETE',
  GetEnvData = 'ENV.GET',
  GetEnvDataDeny = 'ENV.GET.DENY',

  // App
  CreateApp = 'APP.CREATE',
  UpdateApp = 'APP.UPDATE',
  DeleteApp = 'APP.DELETE',

  // Project
  CreateProject = 'PROJECT.CREATE',
  UpdateProject = 'PROJECT.UPDATE',
  DeleteProject = 'PROJECT.DELETE',

  // Project Environment
  CreateProjectEnvironment = 'PROJECT.ENVIRONMENT.CREATE',
  UpdateProjectEnvironment = 'PROJECT.ENVIRONMENT.UPDATE',
  DeleteProjectEnvironment = 'PROJECT.ENVIRONMENT.DELETE',

  // Organization integration connections
  CreateOrganizationIntegrationConnection = 'ORGANIZATION.INTEGRATION-CONNECTION.CREATE',
  DeleteOrganizationIntegrationConnection = 'ORGANIZATION.INTEGRATION-CONNECTION.DELETE',

  CreateToggle = 'TOGGLE.CREATE',
  UpdateToggle = 'TOGGLE.UPDATE',
  DeleteToggle = 'TOGGLE.DELETE',

  CreateSegment = 'SEGMENT.CREATE',
  UpdateSegment = 'SEGMENT.UPDATE',
  DeleteSegment = 'SEGMENT.DELETE',
}

export enum EventReferenceType {
  App = 'App',
  Environment = 'Environment',
  ProjectEnvironment = 'ProjectEnvironment',
  Project = 'Project',
  Segment = 'Segment',
  Toggle = 'Toggle',
}
