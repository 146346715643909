import { useMemo } from 'react';
import { Box, useIsMobile } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import Error from '../../../Error';

import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EventList } from '../../../components/events/EventList';
import { EventReferenceType } from '../../../types/events';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { Skeleton } from '../../../components/common/Skeleton';
import { useSegment } from '../../../providers/SegmentProvider';
import SegmentDetails from '../../../components/segments/SegmentDetails';
import SegmentSettings from './SegmentSettings';

const SegmentDetailContainer = () => {
  const { segment, error, isLoading } = useSegment();

  const isMobile = useIsMobile();
  const ability = useOrganizationAbilityContext();

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        description: <Skeleton height="24px" width="100px" />,
      };
    }
    return {
      title: segment?.name,
      description: segment?.description,
    };
  }, [isLoading, isMobile, segment]);

  const canUpdateSegment = useMemo(() => (segment ? ability.can('update', segment) : false), [ability, segment]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>{segment?.name}</title>
      </Helmet>
      <BreadCrumbPortal entity={segment} />
      <PageHeader {...pageHeaderProps} />
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        gap="2xl"
        justifyContent="space-between"
        alignItems={{ base: 'stretch', tablet: 'center' }}
      >
        <TabNav>
          <TabNavLink to="">Details</TabNavLink>
          <TabNavLink to="events">Events</TabNavLink>
          {canUpdateSegment && <TabNavLink to="settings">Settings</TabNavLink>}
        </TabNav>
      </Box>
      <Routes>
        <Route
          path="/events"
          element={
            <EventList
              references={[
                {
                  id: segment?.id ?? '',
                  type: EventReferenceType.Segment,
                },
                {
                  id: segment?.project.id || '',
                  type: EventReferenceType.Project,
                },
              ]}
            />
          }
        />
        <Route path="/settings" element={<SegmentSettings />} />
        <Route path="/" element={<SegmentDetails />} />
      </Routes>
    </ScrollableMainLayout>
  );
};

export default SegmentDetailContainer;
