import { Box, Card, SidebarInset, SidebarProvider, SidebarTrigger, useIsMobile } from '@hyphen/hyphen-components';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Components
import { CreateTeam } from './Organization/CreateTeam';
import AppContainer from './Organization/Apps/AppContainer';
import CreateApp from './Organization/CreateApp';
import CreateLink from './Organization/CreateLink';
import CreateProject from './Organization/Projects/CreateProject';
import Dashboard from './Organization/Dashboard';
import EnvDashboard from './Organization/EnvDashboard';
import Events from './Organization/Events';
import IntegrationDetails from './Organization/Integrations/IntegrationDetails';
import { IntegrationSetup } from './Organization/Integrations/IntegrationSetup';
import IntegrationsOverview from './Organization/Integrations/IntegrationsOverview';
import ProjectContainer from './Organization/Projects/ProjectContainer';
import Settings from './Organization/Settings';
import ShortLinkDashboard from './Organization/ShortLinkDashboard';
import ShortLinkDetail from './Organization/ShortLinkDetail';
import AppSidebar from '../components/sidebar/AppSidebar';
import TeamContainer from './Organization/TeamContainer';
import Teams from './Organization/Teams';

// Hooks and Utilities
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { useEffect } from 'react';
import { OrganizationProvider, useOrganization } from '../providers/OrganizationProvider';
import { useGetOrganizationBillingAccountQuery } from '../services/organization';
import { OrganizationAbilityProvider } from '../components/auth/OrganizationAbilityProvider';
import { routes } from '..';
import CreateToggle from './Organization/Toggles/CreateToggle';
import ToggleDetailContainer from './Organization/Toggles/ToggleDetailContainer';
import { OrganizationMemberProvider } from '../providers/OrganizationMemberProvider';
import ToggleDashboard from './Organization/ToggleDashboard';
import { ProjectEnvironmentsProvider } from '../providers/ProjectEnvironmentsProvider';
import { ToggleProvider } from '../providers/ToggleProvider';
import { ProjectProvider } from '../providers/ProjectProvider';
import IntegrationConnectionDetails from './Organization/Integrations/IntegrationConnectionDetails';
import { ConnectionProvider } from '../providers/ConnectionProvider';
import CreateSegment from './Organization/Segments/CreateSegment';
import SegmentDetailContainer from './Organization/Segments/SegmentDetailContainer';
import { SegmentProvider } from '../providers/SegmentProvider';

export const OrganizationBase = () => {
  const { isLoading } = useOrganizationsList();

  const isMobile = useIsMobile();

  return (
    <OrganizationProvider>
      <OrganizationAbilityProvider>
        <OrganizationMemberProvider>
          <Box
            direction={{ base: 'column', desktop: 'row' }}
            width="100"
            height="100"
            minHeight="100"
            margin="0"
            alignItems="stretch"
          >
            <SidebarProvider>
              <AppSidebar />
              <SidebarInset>
                <Box direction="row" alignItems="center" gap="sm" height="32px">
                  {isMobile && <SidebarTrigger />}
                  <div id="breadcrumbContainer" />
                </Box>
                <Card height="100" radius={{ base: '0', tablet: 'md' }}>
                  {!isLoading && <MainContent />}
                </Card>
              </SidebarInset>
            </SidebarProvider>
          </Box>
        </OrganizationMemberProvider>
      </OrganizationAbilityProvider>
    </OrganizationProvider>
  );
};

const MainContent = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const { id: orgId } = useOrganization();
  const { isLoading, data } = useGetOrganizationBillingAccountQuery(orgId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (data?.subscription?.status !== 'Active') {
        navigate(`/${routes.getStarted}/${orgId}`);
      }
    }
  }, [isLoading, data, navigate, orgId]);

  if (isLoading) return null;

  return (
    <Routes location={background || location}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/projects/create" element={<CreateProject />} />
      <Route path="/link" element={<ShortLinkDashboard />} />
      <Route path="/link/create" element={<CreateLink />} />
      <Route path="/link/:id" element={<ShortLinkDetail />} />
      <Route path="/env" element={<EnvDashboard />} />
      <Route path="/integrations" element={<IntegrationsOverview />} />
      <Route path="/integrations/setup/:integrationSlug" element={<IntegrationSetup />} />
      <Route path="/integrations/:id" element={<IntegrationDetails />} />
      <Route
        path="/integrations/:integrationId/connections/:connectionId/*"
        element={
          <ConnectionProvider>
            <IntegrationConnectionDetails />
          </ConnectionProvider>
        }
      />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/teams" element={<Teams />} />
      <Route path="/teams/create" element={<CreateTeam />} />
      <Route path="/teams/:id/*" element={<TeamContainer />} />
      <Route path="/events/*" element={<Events />} />
      <Route path="/app/create" element={<CreateApp />} />
      <Route
        path="/:projectId/*"
        element={
          <ProjectProvider>
            <ProjectContainer />
          </ProjectProvider>
        }
      />
      <Route
        path="/:projectId/segments/create"
        element={
          <ProjectProvider>
            <CreateSegment />
          </ProjectProvider>
        }
      />
      <Route
        path="/:projectId/segments/:segmentId/*"
        element={
          <ProjectProvider>
            <SegmentProvider>
              <SegmentDetailContainer />
            </SegmentProvider>
          </ProjectProvider>
        }
      />
      <Route path="/:projectId/toggles/create" element={<CreateToggle />} />
      <Route
        path="/:projectId/app/:appId/*"
        element={
          <ProjectProvider>
            <AppContainer />
          </ProjectProvider>
        }
      />
      <Route
        path="/:projectId/toggles/:toggleId/*"
        element={
          <ProjectProvider>
            <ProjectEnvironmentsProvider>
              <ToggleProvider>
                <ToggleDetailContainer />
              </ToggleProvider>
            </ProjectEnvironmentsProvider>
          </ProjectProvider>
        }
      />
      <Route path="/toggles" element={<ToggleDashboard />} />
      <Route path="/toggles/create" element={<CreateToggle />} />
    </Routes>
  );
};
