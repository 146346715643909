import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Icon,
} from '@hyphen/hyphen-components';
import { FormikErrors } from 'formik';
import { TargetFormSchema } from './TargetForm';
import { useFlag } from '@openfeature/react-sdk';

const STANDARD_CONTEXTS = ['app', 'environment', 'ipAddress', 'targetingKey', 'user.email', 'user.id', 'user.name'];

interface ContextField {
  key: string;
  operator: string;
  value: string;
  contextFieldType: string;
}

interface ContextFieldDropdownProps {
  push: (value: ContextField) => void;
  values: TargetFormSchema;
  errors: FormikErrors<TargetFormSchema>;
}

const ContextFieldDropdown = ({ push, values, errors }: ContextFieldDropdownProps) => {
  const { value: isSegmentsReleased } = useFlag('segments-released', false);

  const handleSelect = (contextFieldType: string, key = '') => {
    if (key === 'segment') {
      push({
        key: 'in',
        operator: 'segment',
        value: '',
        contextFieldType,
      });
    } else {
      push({
        key,
        operator: '==',
        value: '',
        contextFieldType,
      });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" iconSuffix="caret-sm-down" size="sm">
          Add Criteria
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="bottom">
        <Box maxHeight="7xl" overflow="auto">
          {STANDARD_CONTEXTS.map((context) => (
            <DropdownMenuItem
              key={context}
              onSelect={() => handleSelect('', context)}
              disabled={values.parsedLogic.some((logic: any) => logic.key === context)}
            >
              {context}
            </DropdownMenuItem>
          ))}
        </Box>
        <DropdownMenuSeparator />
        {isSegmentsReleased && (
          <DropdownMenuItem onSelect={() => handleSelect('segment', 'segment')}>
            <Icon name="path-intersect" color="secondary" />
            <span>segment</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onSelect={() => handleSelect('customAttributes')}>customAttributes</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => handleSelect('user.customAttributes')}>
          user.customAttributes
        </DropdownMenuItem>
      </DropdownMenuContent>
      {errors.parsedLogic && typeof errors.parsedLogic === 'string' && (
        <Box margin="md 0 0 0" color="danger" fontSize="sm">
          {errors.parsedLogic}
        </Box>
      )}
    </DropdownMenu>
  );
};

export default ContextFieldDropdown;
