import { TeamDetails } from '../../types/teams';
import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';
import { Helmet } from 'react-helmet';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';

interface TeamConnectionsProps {
  team: TeamDetails;
  organization: Organization;
}

export default function TeamConnections({ team, organization }: TeamConnectionsProps) {
  const ability = useOrganizationAbilityContext();
  const canUpdateTeam = ability.can('update', team);

  return (
    <>
      <Helmet title={`${team.name} Integration Connections`} />
      <ConnectionsList
        organizationId={organization.id}
        showAddButton={canUpdateTeam}
        filter={{
          entity: {
            type: 'Team',
            id: team.id,
            name: team.name,
          },
          entityType: 'Team',
        }}
      />
    </>
  );
}
