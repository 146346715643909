import {
  Box,
  toast,
  BadgeVariant,
  BadgeSize,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Icon,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { App, CidrAllowDenyEntry, EnvAccessControls, useUpdateAppSettingsMutation } from '../../services/apps';
import { useCallback, useMemo, useState } from 'react';
import EnvAccessControlForm, { EditCidrAccessControlSchema } from './EnvAccessControlForm';
import { ApiError } from '../ApiError';
import { ListItem } from '../ListItem';
import { ConfirmModal } from '../common/ConfirmModal';

export interface EnvAccessControlItemProps {
  app: App;
  type: 'allow' | 'deny';
  index: number;
  accessControl: CidrAllowDenyEntry;
  isDefault: boolean;
  environmentAlternateId?: string;
  canUpdate: boolean;
  isInherited: boolean;
}

export default function EnvAccessControlItem({
  app,
  type,
  index,
  isDefault,
  environmentAlternateId,
  accessControl,
  canUpdate,
  isInherited,
}: EnvAccessControlItemProps) {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const [updateAppSettings, { isLoading: appUpdateLoading, error }] = useUpdateAppSettingsMutation();
  const [isEditing, setEditing] = useState(false);

  const initialValues = {
    description: accessControl.description,
    cidr: accessControl.cidr,
  };

  const badgeProps = useMemo(() => {
    return isInherited
      ? {
          variant: 'light-grey' as BadgeVariant,
          message: 'Inherited',
          size: 'sm' as BadgeSize,
          tooltipContent: 'Rule is inherited from the default (all) environment.',
        }
      : {
          variant: 'blue' as BadgeVariant,
          message: 'Direct',
          size: 'sm' as BadgeSize,
          tooltipContent: 'Access was granted directly to this environment.',
        };
  }, [isInherited]);

  const handleEditSave = useCallback(
    async (data: EditCidrAccessControlSchema) => {
      const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
      const listBefore = accessControlsBefore[type] || [];
      const updatedList = [
        ...listBefore.slice(0, index),
        { type: 'cidr' as const, description: data.description, cidr: data.cidr },
        ...listBefore.slice(index + 1),
      ];

      const { error } = await updateAccessControls(
        app,
        type,
        updatedList,
        isDefault,
        updateAppSettings,
        environmentAlternateId,
      );

      if (!error) {
        toast.success('Updated access control rule');
        setEditing(false);
      }
    },
    [app, index, isDefault, environmentAlternateId, type, updateAppSettings],
  );

  const handleDelete = useCallback(async () => {
    const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
    const listBefore = accessControlsBefore[type] || [];
    const updatedList = [...listBefore.slice(0, index), ...listBefore.slice(index + 1)];

    const { error } = await updateAccessControls(
      app,
      type,
      updatedList,
      isDefault,
      updateAppSettings,
      environmentAlternateId,
    );

    if (!error) {
      toast.success('Access control rule deleted.');
      closeDeleteModal();
    } else {
      toast.error('Failed to delete access control rule.');
    }
  }, [app, closeDeleteModal, isDefault, environmentAlternateId, type, index, updateAppSettings]);

  const dropdownContent = useMemo(() => {
    if (isInherited) return undefined;
    return (
      <DropdownMenuGroup>
        <DropdownMenuItem onSelect={() => setEditing(true)}>
          <Icon name="pencil" color="tertiary" /> <span>Edit</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="font-color-danger" onSelect={openDeleteModal}>
          <Icon name="c-remove" /> <span>Delete</span>
        </DropdownMenuItem>
      </DropdownMenuGroup>
    );
  }, [isInherited, openDeleteModal]);

  return (
    <Box borderWidth="sm 0 0 0" borderColor="subtle" className="row-item" width="100">
      {isEditing ? (
        <EnvAccessControlForm
          isLoading={appUpdateLoading}
          handleSave={handleEditSave}
          handleCancel={() => setEditing(false)}
          initialValues={initialValues}
        />
      ) : (
        <>
          <ListItem
            badge={!isDefault ? badgeProps : undefined}
            title={{ label: accessControl.description }}
            infoText={accessControl.cidr}
            dropdown={dropdownContent}
            reserveDropdownBtnSpace={true}
          />
        </>
      )}
      {error && <ApiError error={error} title="Error updating access control rules" />}
      <ConfirmModal
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={appUpdateLoading}
        title="Delete Firewall Rule?"
        message={isDefault ? 'This will also delete the rule from all environments' : undefined}
      />
    </Box>
  );
}

// These below helpers are exported to be used by add as well.

export const getAccessControlsBefore = (app: App, isDefault: boolean, environmentAlternateId?: string) => {
  if (isDefault) {
    return app.settings?.env?.accessControls || { allow: [], deny: [] };
  }
  return app.settings?.env?.accessControls?.environments?.[environmentAlternateId!] || { allow: [], deny: [] };
};

export const buildUpdatedAccessControls = (
  accessControlsBefore: EnvAccessControls,
  type: 'allow' | 'deny',
  updatedList: Array<CidrAllowDenyEntry>,
  isDefault: boolean,
  environmentAlternateId?: string,
) => {
  if (isDefault) {
    return {
      ...accessControlsBefore,
      [type]: updatedList,
    };
  }

  return {
    environments: {
      [environmentAlternateId!]: {
        ...accessControlsBefore,
        [type]: updatedList,
      },
    },
  };
};

export const updateAccessControls = async (
  app: App,
  type: 'allow' | 'deny',
  updatedList: Array<CidrAllowDenyEntry>,
  isDefault: boolean,
  updateAppSettings: ReturnType<typeof useUpdateAppSettingsMutation>[0],
  environmentAlternateId?: string,
) => {
  const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
  const updatedAccessControls = buildUpdatedAccessControls(
    accessControlsBefore,
    type,
    updatedList,
    isDefault,
    environmentAlternateId,
  );

  const results = await updateAppSettings({
    organizationId: app.organization.id,
    appId: app.id,
    body: {
      settings: {
        env: {
          accessControls: updatedAccessControls,
        },
      },
    },
  });

  return results;
};
