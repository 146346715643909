import { Icon } from '@hyphen/hyphen-components';
import { IconName } from '@hyphen/hyphen-design-tokens/build/types';
import { IconProps } from '@hyphen/hyphen-components';
import { EntityTypes } from '@hyphen/nucleus/dist/types';

/**
 * @returns If defined, display the icon for the entity type
 */
export const EntityIcon = ({ type, ...props }: { type: EntityTypes } & Omit<IconProps, 'name'>) => {
  const iconMap: { [key in EntityTypes]?: IconName } = {
    App: 'block',
    LinkCode: 'logo-link',
    Team: 'users',
    Toggle: 'logo-toggle',
    Project: 'blocks',
    Member: 'user',
  };

  if (!iconMap[type]) {
    return null;
  }

  return <Icon name={iconMap[type] as IconName} {...props} />;
};
