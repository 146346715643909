import { Box, BoxProps } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';
import { Skeleton } from './Skeleton';
import TooltipIcon from './TooltipIcon';

interface InfoBoxProps extends BoxProps {
  title: string;
  children?: ReactNode;
  isLoading: boolean;
  skeletonWidth: string;
  skeletonHeight: string;
  helpText?: string;
}

const InfoBox = ({
  title,
  children,
  isLoading,
  skeletonWidth,
  skeletonHeight,
  helpText,
  ...boxProps
}: InfoBoxProps) => (
  <Box
    background="primary"
    alignItems={{ base: 'center', tablet: 'flex-start' }}
    justifyContent={{ base: 'space-between', tablet: 'flex-start' }}
    direction={{ base: 'row', tablet: 'column' }}
    gap="lg"
    minWidth="7xl"
    width="100"
    {...boxProps}
  >
    <Box direction="row" gap="xs" alignItems="center">
      <Box fontWeight="semibold" color="tertiary" fontSize="xs">
        {title}
      </Box>
      {helpText && <TooltipIcon content={helpText} name="c-question" color="tertiary" />}
    </Box>
    {isLoading ? <Skeleton width={skeletonWidth} height={skeletonHeight} /> : children}
  </Box>
);

export default InfoBox;
