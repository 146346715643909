import { useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Route, Routes } from 'react-router-dom';
import ClickToCopy from '../../../components/common/ClickToCopy';
import Error from '../../../Error';
import ProjectSettings from './ProjectSettings';
import ProjectOverview from './ProjectOverview';
import { TabNavLink, TabNav } from '../../../components/TabNav';
import ProjectAccess from './ProjectAccess';
import ProjectToggles from './ProjectToggles';
import ProjectConnections from './ProjectConnections';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { Badge, Box, useIsMobile } from '@hyphen/hyphen-components';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import AccessBanner from '../../../components/common/AccessBanner';
import { subject } from '@casl/ability';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import ProjectSegments from './ProjectSegments';
import { useFlag } from '@openfeature/react-sdk';
import { ProjectEnvironmentsProvider } from '../../../providers/ProjectEnvironmentsProvider';

export default function ProjectContainer() {
  const layoutRef = useRef<HTMLDivElement>(null);
  const { project, isLoading, error } = useProject();

  const { value: isSegmentsReleased } = useFlag('segments-released', false);

  const isMobile = useIsMobile();

  const ability = useOrganizationAbilityContext();
  const canReadProjectConnections = useMemo(
    () =>
      !isLoading && project
        ? ability.can(
          'read',
          subject(EntityNames.IntegrationConnection, {
            project: { id: project.id },
            organization: { id: project.organization.id },
          }),
        )
        : false,
    [ability, isLoading, project],
  );

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        description: <Skeleton height="30px" width="100px" />,
        favoriteId: '',
      };
    }
    return {
      title: project?.name,
      description: (
        <Box direction="row" gap="sm" alignItems="center">
          <ClickToCopy text={project?.alternateId || ''} fontSize="xs" />
          {project?.isMonorepo && <Badge variant="blue" size="sm" message="Monorepo" />}
        </Box>
      ),
      favoriteId: project?.id,
    };
  }, [isLoading, project?.name, project?.alternateId, project?.id, project?.isMonorepo, isMobile]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout ref={layoutRef} fullWidth>
      <Helmet>
        <title>{project?.name || ''}</title>
      </Helmet>
      {!isLoading && project && (
        <>
          <BreadCrumbPortal entity={project} />
          <AccessBanner layoutRef={layoutRef} entity={project} />
        </>
      )}

      <PageHeader {...pageHeaderProps} />
      <TabNav>
        <TabNavLink to="">Overview</TabNavLink>
        <TabNavLink to="toggles">Toggles</TabNavLink>
        {isSegmentsReleased && <TabNavLink to="segments">Segments</TabNavLink>}
        {canReadProjectConnections && <TabNavLink to="connections">Connections</TabNavLink>}
        <TabNavLink to="access">Access</TabNavLink>
        <TabNavLink to="settings">Settings</TabNavLink>
      </TabNav>

      <Routes>
        <Route path="" element={<ProjectOverview />} />
        <Route
          path="toggles"
          element={
            <ProjectEnvironmentsProvider>
              <ProjectToggles />
            </ProjectEnvironmentsProvider>
          }
        />
        <Route path="segments" element={<ProjectSegments />} />
        <Route path="connections" element={<ProjectConnections />} />
        <Route path="access" element={<ProjectAccess />} />
        <Route path="settings" element={<ProjectSettings />} />
      </Routes>
    </ScrollableMainLayout>
  );
}
